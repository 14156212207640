@import './colors.scss';

.diceScore {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background-color:$field-color;

	.firstLine {
		display: flex;
		flex-direction: row;
		width: 80%;
		justify-content: space-between;
		margin-bottom: -20px;

		.line {
			display: flex;
			align-items: center;
		}

		.support {
			margin-left: 20px;
			padding-top: 2px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			background-color: $field-color;
			border: 1px solid $main-color;
			border-radius: 5px;
	
			transition: background-color .3s;
			.fa-solid{
				display: flex;
				justify-content: center;
				align-items: center;
				color: $main-color;
				font-size: .8rem;
				transition: color .3s;
			}
		
		}

		.support:hover {background-color: $main-color;
			.fa-solid{color: $field-color;}
		border: none;}
		
		.supportWindow {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 300px;
			background-color: $main-color
			
		}
		
	}

	.dices {
		display: flex;
		flex-direction: row;
		color: $main-color;
		margin-top: -10px;
	}

	.buttons {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-bottom: 8px;
	}

	.fa-solid {
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
		font-size: 40px;
		margin: 10px 10px;
		cursor: pointer;
	}

	.active {
		border: 1px solid rgba($main-color, 0.6);
		border-radius: 5px;
	}
}

.score {
	display: none;
}

.scoreActive {
	display: flex;
}

.support {
	margin-left: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	background-color: $field-color;
	border: 1px solid $main-color;
	border-radius: 5px;

}

.supportWindow {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 300px;
	background-color: $main-color;
	h3,p{color:$field-color;}
}

.throwButton {
	display: none;
	background-color: $field-color;
	color: $main-color;
	font-size: 1rem;
	border: 1px solid $main-color;
	border-radius: 5px;
	padding: 10px 20px;
	margin: 10px 10px;
	transition: background-color 0.3s, color 0.3s;
}

.throwButton:hover {
	background-color: $main-color;
	color: $field-color;
}

.activeButton {
	display: flex;
}

@media (min-width: 576px) {
	.diceScore {
		width: 80%;
		border-radius: 10px;
	}
}
