@import './colors.scss';

.scores {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background-color: $field-color;
	padding-bottom: 20px;
	margin-bottom: 20px;

	.scoreBoardHeader {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;

		.nonSortBtn {
			height: 20px;
			background-color: $field-color;
			border: 1px solid $main-color;
			border-radius: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100px;
			height: 20px;
		}

		
	}

	.firstcolumname {
		width: 300px;
		th {
			div {
				display: flex;
				justify-content: center;
				align-items: center;
				.sortBtn {
					margin-left: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					height: 20px;
					width: 20px;
					border-radius: 5px;
					background-color: $field-color;
					border: 1px solid $main-color;
					transition: background-color .3s;

					.fa-solid {
						font-size: 0.8rem;
						color: $main-color;
						transition: color .3s;
					}
				}
				.sortBtn:hover {
					background-color: $main-color;

					.fa-solid {
						font-size: 0.8rem;
						color: $field-color;
					}
				}
				.title {
					font-size: 1rem;
					font-weight: lighter;
				}
			}
		}
	}
	.firstcolumn {
		.data {
			font-size: 0.9rem;
			font-weight: lighter;
		}
	}
}

@media (min-width: 576px) {
	.scores {
		width: 80%;
		border-radius: 10px;
	}
}
