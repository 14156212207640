@import './colors.scss';

.diceTeacher {
	display: none;
	margin-top: 20px;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background-color: $field-color;

	.buttons {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-bottom: 10px;

		.btn {
			background-color: $main-color;
			color: $field-color;
			font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
			font-weight: bolder;
			font-size: 1rem;
			border: none;
			border-radius: 10px;
			padding: 10px 20px;
			margin: 5px 10px;
			transition: background-color 0.3s, color 0.3s;
		}

		.active {
			display: flex;
		}
	}

	.firstLine {
		display: flex;
		align-items: center;

		.support, .supportWindow {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			background-color: $field-color;
			border: 1px solid $main-color;
			border-radius: 5px;
			transition: background-color .3s;
		}

		.support {
			margin-left: 20px;
			color: $main-color;

			&:hover {
				background-color: $main-color;
				color: $field-color;
			}
		}

		.supportWindow {
			width: 300px;
			background-color: $main-color;
		}
	}
}

.active {
	display: flex;
}

@media (min-width: 576px) {
	.diceTeacher {
		width: 80%;
		border-radius: 10px;
	}
}
