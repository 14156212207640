@import './colors.scss';

html {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	background-color: $background-color;
}

.App {
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	text-align: center;
	width: 100%;
	height: 100vh;
	padding-top: 20px;
	position: relative; 
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

	p, H1, h2 {
		color: $main-color;
	}

	p {
		font-size: 1rem;
	}

	H1 {
		font-size: 2rem;
		margin-bottom: 10px;
	}

	h2 {
		text-align: center;
		font-size: 1.2rem;
		font-weight: lighter;
		margin-bottom: 10px;
	}
}

button {
	cursor: pointer;
}
