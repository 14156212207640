@import './colors.scss';
.navbar {
	display: column;
	background-color: $field-color;
	width: 100%;


	.range {
		.switcher {
			display: flex;
			justify-content: center;
			align-items: center;

			.txt {
				padding-bottom: 20px;
			}

			.rangeinput {
				width: 80px;
				margin-bottom: 20px;
				margin-left: 20px;
				margin-right: 20px;
			}

			.rangeinput {
				-webkit-appearance: none;
				width: 100px;
			}

			.rangeinput::-webkit-slider-runnable-track {
				-webkit-appearance: none;
				height: 30px;
				background: $background-color;
				border-radius: 20px;
			}
			.rangeinput::-webkit-slider-thumb {
				-webkit-appearance: none;
				margin-top: 1px;
				height: 29px;
				background: $main-color;
				border: none;
				border-radius: 20px;
				width: 50%;
			}

			.rangeinput::-moz-range-track {
				-webkit-appearance: none;
				height: 30px;
				background: $background-color;
				border-radius: 20px;
			}

			.rangeinput::-moz-range-thumb {
				margin-top: 1px;
				height: 29px;
				background: $main-color;
				border: none;
				border-radius: 20px;
				width: 50%;
			}
		}
	}

	.navtext {
		display: flex;
		align-items: center;
		justify-content: center;

		.logo {	display: flex;
			align-items: center;
			justify-content: center;
			i{
				font-size: 3rem;
				color: $main-color;
			}
			display: flex;
			width: 6em;
			height: 6em;
			margin-right: 5px;
		}

		.title {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-left: 5px;
			line-height: 0rem;

			H1 {
				font-size: 2rem;

				font-weight: lighter;

				margin-bottom: 10px;
			}

			p {
				font-size: 1rem;
			}
		}

		.support {
			margin-left: 20px;
			padding-top: 1px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			background-color: #fff;
			border: 1px solid $main-color;
			border-radius: 5px;
			color: $main-color;
			background-color: color 0.3s;
			
			.fa-solid {
				color: $main-color;
				transition: color 0.3s;
			}
		}

		.supportWindow {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 300px;
			background-color: $main-color;
			h3,p{color:$field-color;}
		}

		.support:hover {
			background-color: $main-color;;
			.fa-solid {
				color: rgb(255, 255, 255);
				transition: color 0.3s;
			}
		}
	}
}

@media (min-width: 576px) {
	.navbar {
		width: 80%;
		border-radius: 10px;
	}
}
